<template>
    <div class="ManMachine">
        <el-form ref="form" :rules="rules" :model="form" size="small">
            <el-form-item>
                请输入图形中的验证码
            </el-form-item>
            <el-form-item prop="numeric">
                <el-input v-model="form.numeric" placeholder="按照下图中的字符填写，不区分大小写"></el-input>
            </el-form-item>
            <el-form-item>
                <img class="numeric" :src="url" alt="">
                <el-button type="text" @click="reImg">看不清楚？换一个</el-button>
            </el-form-item>
        </el-form>
        <div class="end">
            <el-button @click="cancel">取消</el-button>
            <el-button type="primary" @click="confirm">确定</el-button>
        </div>
    </div>
</template>
<script>
// import ManMachine from '@/assets/ManMachine.png'
export default {
    components: {},
    props: {
        url: String
    },
    computed: {},
    data() {
        return {
            form: {
                numeric: "",
            },

            rules: {
                numeric: [
                    //
                    {
                        required: true,
                        message: '请输入验证码',
                        trigger: 'change'
                    },
                ],
            },
        }
    },
    mounted() {
        this.initFun()
    },
    methods: {
        initFun() {
            this.form.numeric = ""
        },
        reImg() {
            this.$emit("reImg")
        },
        cancel() {
            this.$emit("cancel")
        },
        confirm() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.$emit("confirm", this.form)
                }
            });
        }
    }
}
</script>
<style scoped lang="scss">
@import "@/assets/css/var.scss";
.ManMachine {
    width: 348px;
    .numeric{
        margin-right: 24px;
    }
    .end{
        text-align: right;
    }
}
</style>