import axios from './axios.js';
import Qs from 'qs';
// import { Dialog } from 'vant';
import { Notification } from 'element-ui';
var base_url = config.base_url || "https://siteapi.gdhhlsy.com/"

var url = {
    // registerSms: base_url + "User/registerSms",
    generateNumeric: base_url + "User/generateNumeric",
    registerSms: base_url + "User/registerSmsNumeric",
    register: base_url + "User/register",
    login: base_url + "User/login",
    // updatePasswordSms: base_url + "User/updatePasswordSms",
    updatePasswordSms: base_url + "User/updatePasswordSmsNumeric",
    updatePassword: base_url + "User/updatePassword",
    getUserInfo: base_url + "User/getUserInfo",
    applicationAdd: base_url + "User/applicationAdd",
    BoxVersion: base_url + "BoxVersion/find",

    Banner: base_url + "BannerGuangdong/getList", //banner列表
    Demo: base_url + "Demo/getList", //Demo列表
    Detail: base_url + "Detail/find", //文章详情
    ContactUs: base_url + "ContactUs/find", //联系我们
    Protocol: base_url + "Protocol/find", //联系我们
    DemoVideo: base_url + "DemoVideoGuangzhou/getList", //解决方案
    DemoVideoDetail: base_url + "DemoVideoGuangzhou/detail", //解决方案
    getNoList: base_url + "DemoVideoGuangzhou/getNoList", //解决方案

    Document: base_url + "Document/getList", //联系我们
    Video: base_url + "Video/getList", //联系我们
    VideoFind: base_url + "Video/find", //联系我们

    DetailNew: base_url + "DetailNew/find",
    DetailList: base_url + "DetailNew/getList",
}

export default {
    post(config) {
        var p_url = url[config.url] || config.url
        config.data = config.data || {}
        if (this.store) {
            if (this.store.getters.token) {
                config.data.token = this.store.getters.token
            }
        }
        if (config.type == "get") {
            p_url = p_url + this.url_passer(config.data, "?")
        }
        return axios({
                url: p_url,
                method: config.type || 'post',
                data: config.data
            })
            .then((res) => {
                try {
                    res.data = JSON.parse(res.data)
                } catch (err) {

                }
                if (res.data.status != 200) {
                    if (res.data.status == 301) {
                        this.store.commit("tokenSet", "")
                    }
                    if (config.fail) {
                        config.fail(res.data)
                        return
                    } else {
                        Notification.error({
                            title: '提示',
                            message: res.data.message
                        })
                    }
                    return
                }
                /*Notification.success({
                    title: '提示',
                    message: res.data.msg
                })*/
                console.log("res.data", res.data)
                config.success && config.success(res.data)
            })
            .catch(function(error) {
                if (config.fail) {
                    config.fail({
                        errCode: 500,
                        msg: "接口返回失败"
                    })
                } else {
                    Notification.error({
                        title: '提示',
                        message: "接口返回失败"
                    })
                }
                config.error && config.error({
                    url: p_url
                })
            });
    },
    url_obj() {
        return Qs.parse(location.search.replace("?", ""))
    },
    url_str(obj, _h = "") {
        return _h + Qs.stringify(obj)
    },
    get_url_argument(name) {
        var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
        var r = decodeURI(window.location.search).substr(1).match(reg)
        if (r != null) return unescape(r[2])
        return null
    },
    get_url_argument_obj() {
        var obj = {}
        var reg = new RegExp('([^\\?|&]*)=([^&]*)', 'g')
        var r = decodeURI(window.location.search).match(reg)
        for (var i = 0; i < r.length; i++) {
            var t = r[i].split('=')
            obj[t[0]] = t[1]
        }
        return obj
    },
    url_passer(obj, key) {
        var _this_ = this
        var returnStr = ''
        switch (typeof obj) {
            case 'string':
            case 'number':
            case 'boolean':
                returnStr += '&' + key + '=' + obj
                break
            default:
                for (var i in obj) {
                    if (typeof obj[i] !== 'function') {
                        if (key && key !== '?' && key !== '&') {
                            returnStr += _this_.url_passer(obj[i], key + '[' + i + ']')
                        } else {
                            returnStr += _this_.url_passer(obj[i], i)
                        }
                    }
                }
                break
        }
        switch (key) {
            case '&':
                return returnStr.replace(/^./, '')
            case '?':
                returnStr = "?" + returnStr
        }
        /*if (key === '?') {
            return returnStr.replace(/^./, '?')
        } else if (key === '&') {
            return returnStr.replace(/^./, '')
        }*/
        return returnStr
    }
}